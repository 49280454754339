import { InputAdornment, TextField, Typography } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import * as yup from "yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";

type Props = {};

const URL = "/password/forgot-password/byEmail";

const ForgetPassword = (props: Props) => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };

  const validateLoginSchema = yup.object().shape({
    email: yup.string().email().required("Required"),
  });

  const handleSubmit = async ({ email }: FormikValues) => {
    try {
      await axios.get(URL, {
        params: {
          email,
          redirectUrl: `localhost:3000/reset_password?email=${email}`,
        },
        headers: {
          "client-id": "WAYABANK",
          "client-type": "PERSONAL",
        },
      });
      navigate(`/check_mail?email=${email}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center bg-gray-100 justify-center h-screen font-lato">
      <div className="px-5 border-gray-200 py-9 border-[1px] bg-white rounded-md h-fit w-[400px]">
        <div className="flex text-center flex-col mb-8 items-center justify-center">
          <Typography variant="h5" className="font-bold">
            Forgot Password
          </Typography>
          <Typography variant="subtitle2" color="gray" className="mt-1">
            Ouch sorry, you forgot your password. kindly enter your email
            address to get a reset link
          </Typography>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validateLoginSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form>
              <div className="flex flex-col items-center justify-center gap-4">
                <TextField
                  label="Email address"
                  name="email"
                  placeholder="Enter your work email address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="warning"
                  error={!!touched.email && !!errors.email}
                  helperText={!!touched.email && errors.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <button
                  type="submit"
                  className="transition-all duration-300 col-span-2 py-3 w-full text-white hover:border-[#FF7D58] border-[1px] border-transparent bg-[#FF7D58] hover:bg-transparent hover:text-[#FF7D58] rounded-md"
                >
                  Send reset instructions
                </button>
                <Typography variant="subtitle2" color="gray">
                  Remember your password?{" "}
                  <span
                    className="text-[#FF7D58] cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    Click here
                  </span>
                </Typography>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgetPassword;
