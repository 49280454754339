import { Typography } from "@mui/material";
import SigninCards from "../../components/cards/signinCard";
import { Link } from "react-router-dom";
import Back from "../../components/navigation/back";

const SigninOptionsPage = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center w-[600px] 2xl:w-[620px] h-[75vh] mx-auto my-auto">
        <Back link="/" />
        <Typography
          variant="h3"
          className="font-bold mb-4 text-4xl text-center"
        >
          Choose your sign in option
        </Typography>
        <Typography
          variant="subtitle1"
          color="gray"
          className="text-center text-base"
        >
          Our comprehensive design system offers you an unparalleled range of
          components, sparking creativity and boosting efficiency.
        </Typography>
        <div className="grid grid-cols-2 2xl:gap-10 lg:gap-8 mt-5">
          <SigninCards
            title="Group"
            subtitle="Sign in into the group to access reports and KPI"
          />
          <SigninCards
            title="Bank"
            subtitle="Sign in into the group to access reports and KPI"
          />
          <SigninCards
            title="Social Marketplace"
            subtitle="Sign in into the group to access reports and KPI"
          />
          <SigninCards
            title="Payment Gateway"
            subtitle="Sign in into the group to access reports and KPI"
          />
        </div>
        <Link
          to="/auth/as_Admin"
          className="transition-all duration-300 col-span-2 py-3 mt-10 w-full text-[#FF7D58] border-[#FF7D58] border-[1px] hover:border-transparent hover:bg-[#FF7D58] hover:text-white rounded-md text-center"
        >
          Login as Admin
        </Link>
      </div>
      <div className="w-[95%] bg-[#FFEFEA] rounded-t-xl h-[25vh] mx-auto flex justify-center items-center flex-col">
        <div className="flex flex-col w-[420px]">
          <Typography
            variant="h4"
            className="text-2xl font-extrabold text-center text-[#FF6634]"
          >
            Report and Analytics Dashboard
          </Typography>
          <Typography
            variant="subtitle1"
            color="gray"
            className="mt-2 text-center text-sm leading-5"
          >
            Our comprehensive design system offers you an unparalleled range of
            components, sparking creativity and boosting efficiency.
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SigninOptionsPage;
