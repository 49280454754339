import { Button } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

type Props = {
  link?: string
}

const GoBack = ({ link }: Props) => {
  const navigate = useNavigate()
  return (
    <Button className='absolute top-10 left-5 hover:bg-transparent text-black font-semibold underline capitalize' onClick={() => link ? navigate(link) : navigate(-1)} startIcon={<KeyboardArrowLeftIcon />}>back</Button>
  )
}

export default GoBack
