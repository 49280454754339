import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";

type Props = {};

const routes: {
  url: string;
  name: string;
  icon: ReactElement<any>;
}[] = [
  {
    url: "/wayaquick/user",
    name: "User",
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    url: "/wayaquick/commission_payment",
    name: "Commision Payment",
    icon: <CardMembershipOutlinedIcon />,
  },
  {
    url: "/wayaquick/revenue",
    name: "Revenue",
    icon: <EqualizerOutlinedIcon />,
  },
  {
    url: "/wayaquick/invoice",
    name: "Invoice",
    icon: <FileCopyOutlinedIcon />,
  },
  {
    url: "/wayaquick/payment_page_terminal",
    name: "Payment page terminal",
    icon: <QrCodeScannerOutlinedIcon />,
  },
  {
    url: "/wayaquick/payment_page",
    name: "Payment page",
    icon: <QrCodeScannerOutlinedIcon />,
  },
  {
    url: "/wayaquick/kyc_verification",
    name: "Kyc verification",
    icon: <PhoneIphoneOutlinedIcon />,
  },
  {
    url: "/wayaquick/sms",
    name: "Sms",
    icon: <CreditScoreOutlinedIcon />,
  },
  {
    url: "/wayaquick/account_creation_and_login",
    name: "Account creation & login",
    icon: <MarkunreadMailboxOutlinedIcon />,
  },
  {
    url: "/wayaquick/profiling",
    name: "Profiling",
    icon: <MarkunreadMailboxOutlinedIcon />,
  },
];

const QuickSidebar = (props: Props) => {
  return (
    <>
      <ul className="flex flex-col h-full justify-evenly mt-14 float-left gap-1.5 clear-right w-[21vw]">
        {routes.map((route, index) => (
          <NavLink
            to={route.url}
            key={index}
            className={({ isActive, isPending }) =>
              isActive
                ? "bg-[#FFEFEA] text-[#FF5D2E] py-2 h-full hover:bg-gray-100 px-8 flex items-center rounded-lg"
                : "py-2 h-full text-[#8C8C8C] flex items-center hover:bg-gray-100 px-8 rounded-lg"
            }
          >
            <span className="mr-3 text-sm">{route.icon}</span> {route.name}
          </NavLink>
        ))}
      </ul>
    </>
  );
};

export default QuickSidebar;
