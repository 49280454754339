import { Skeleton, Typography } from '@mui/material'
import React from 'react'
import { formatMoney } from '../../utils/formatter'

type Props = {
  title: string
  value: string
  percent: string
  isLoading?: boolean
}

const DashboardCards = ({ title, value, isLoading }: Props) => {
  return (
    <>
      <div className='p-4 pb-2 rounded-lg border-[1px] border-gray-200 w-full bg-white'>
        <Typography variant='subtitle2' color="gray" className='tracking-widest text-xs uppercase'>{title}</Typography>
        <div className='flex justify-between mt-2'>
          {!isLoading ? <span className='text-black text-lg font-bold'>{value}</span> :
          <Skeleton variant="circular" width={20} height={20} />}
          {/* <span className={`${percent.startsWith("+") ? `text-green-500 text-semibold` : `text-red-500`} text-semibold`}>{percent}</span> */}
        </div>
      </div>
    </>
  )
}

export default DashboardCards
