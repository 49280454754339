import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useField } from "formik";
import { MenuItem, Select } from "@mui/material";

type Props = {
  name: string;
  label: string;
  options?: { id: string; report: string }[];
};

const SelectInput = ({ label, options, name, ...props }: Props) => {
  const [field, meta, helpers] = useField(name);
  return (
    <FormControl className="col-span-2" fullWidth>
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        label={label}
        {...field}
        {...props}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.report}>
            <em>{option.report}</em>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
