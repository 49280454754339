import { InputAdornment, TextField, Typography } from '@mui/material'
import React, {useState} from 'react'
import RadioButton from '../../components/forms/radioButton'
import GoBack from '../../components/navigation/goBack'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Button, Modal } from 'antd';
import { Form, Formik } from 'formik';
import { FormikValues } from "formik";
import * as yup from "yup";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { customBaseUrl, httpPost } from '../../api/https';
import Swal from 'sweetalert2';

type Props = {}

const AddUser = (props: Props) => {
  const [selectedValue, setSelectedValue] = React.useState('Group');
  const [showReg, setShowReg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    email: "",
    password: "",
    lastName:"",
    firstName: "",
    role: "INITIATOR"
  }

  const validateLoginSchema = yup.object().shape({
    email: yup.string().email().required("Required"),
    password: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    firstName: yup.string().required("Required")
  })

  const handleSubmit = async ({ email, password, firstName, lastName }: FormikValues) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
      role: 'INITIATOR',
      access: selectedValue === 'Bank' 
        ? 'WAYABANK' 
        : selectedValue === 'Group'
        ? 'GROUP'
        : selectedValue === 'Social marketplace'
        ? 'WAYAGRAM'
        : "WAYAQUICK"

    }
    try {
      const response = await httpPost(`${customBaseUrl.baseUrl}/auth/register-report-admin`,
        data,
        false
      );
      if (response.success === true) {
        setShowReg(false);
        Swal.fire('Done', response?.message);
      } else {
        Swal.fire('Oops!', response?.message);
      }
    } catch (error) {
      console.log(error);
    }  }
  return (
    <div className='relative'>
      {/* <GoBack /> */}
      <Modal 
        title={`REGISTER ${selectedValue.toUpperCase()} ADMIN`} 
        open={showReg} onOk={handleSubmit}
        onCancel={()=> setShowReg(false)}
        footer={null}
        // footer={[
        //   <Button key="back" onClick={handleSubmit}>
        //     Register
        //   </Button>
        // ]}
      >
        <Formik
        initialValues={initialValues}
        validationSchema={validateLoginSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <div className='flex flex-col items-center justify-center gap-4'>
            <div style={{ margin: 5 }}>
              <TextField
                label="First Name"
                name='firstName'
                placeholder='Enter your first name'
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.firstName && !!errors.firstName}
                helperText={!!touched.firstName && errors.firstName}
                fullWidth
              />
              </div>
              <div style={{ margin: 5 }}>
              <TextField
                label="Last Name"
                name='lastName'
                placeholder='Enter your last name'
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.lastName && !!errors.lastName}
                helperText={!!touched.lastName && errors.lastName}
                fullWidth
              />
              </div>
            <div style={{ margin: 5 }}>
              <TextField
                label="Email address"
                name='email'
                placeholder='Enter your work email address'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
              </div>
              <div style={{ margin: 5 }}>
              <TextField
                label="Password"
                name='password'
                type={showPassword ? "text" : "password"}
                placeholder='Enter password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.password && !!errors.password}
                helperText={!!touched.password && errors.password}
                InputProps={{
                  className: "cursor-pointer",
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? <VisibilityOffIcon onClick={() => setShowPassword(false)} /> : <VisibilityIcon onClick={() => setShowPassword(true)} />}
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
              </div>
              <button
                style={{ marginLeft: '40%', marginTop: '20px', borderRadius: '10%', background:'#ff6700', padding: '10px', fontSize: 16}}
                type='submit'
                className='grid-span-2 flex items-center justify-center transition-all duration-300 col-span-2 py-3 w-full text-white hover:border-[#FF7D58] border-[1px] border-transparent bg-[#FF7D58] hover:bg-transparent hover:text-[#FF7D58] mt-12 rounded-md'
                >
                Register
              </button>
            </div>
          </Form>
        )}
      </Formik>
      </Modal>
      <div className='flex flex-col justify-center pt-20 tems-center text-center'>
        <Typography variant='h4'>Add a new user</Typography>
        <Typography variant='subtitle2' color="gray">Select the business you want to add the user to</Typography>
      </div>
      <div className='grid gap-8 mt-20 grid-cols-2 w-1/2 mx-auto'>
        <RadioButton value='Group' subtitle='Select the group business as the business you want the user to have privilege to' selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        <RadioButton value='Bank' subtitle='Select  bank as business  the business you want the user to have privilege to' selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        <RadioButton value='Social marketplace' subtitle='Select  bank as business  the business you want the user to have privilege to' selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        <RadioButton value='Payment gateway' subtitle='Select  bank as business  the business you want the user to have privilege to' selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        <button
          disabled={!selectedValue}
          onClick={() => setShowReg(true)}
          type='submit'
          className='grid-span-2 flex items-center justify-center transition-all duration-300 col-span-2 py-3 w-full text-white hover:border-[#FF7D58] border-[1px] border-transparent bg-[#FF7D58] hover:bg-transparent hover:text-[#FF7D58] mt-12 rounded-md'
        >
          <ArrowForwardOutlinedIcon className='mr-2' />
          Proceed
        </button>
      </div>
    </div>
  )
}

export default AddUser
