import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';

type Props = {}

const GroupPosTerminal = (props: Props) => {
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey David Okunlola -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='count of total issued pos terminals by OEM (nexgo, morefun, pax, topwise etc)' value="2500" percent="+36%" />
        <DashboardCards title='count of active pos terminals by OEM (nexgo, morefun, pax, topwise etc)' value="1800" percent="-36%" />
        <DashboardCards title='total value of card payment gateway outgoing' value="2500" percent="+36%" />
        <DashboardCards title='total count of card payment gateway outgoing' value="1800" percent="-36%" />
      </div>

    </div>
  )
}

export default GroupPosTerminal
