import { Card, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

type Props = {
  title: string
  subtitle: string
  link: string
}

const SelectDashboardCard = ({ link, title, subtitle }: Props) => {
  return (
    <Link to={link}>
      <Card className='flex p-3 relative transition-all duration-300 justify-center items-center h-32 w-full hover:border-[#FF7D58] cursor-pointer border-[1px] border-pink-200'>
        <div className='flex text-center flex-col w-56'>
          <Typography variant='h5' className='font-bold text-base mb-1'>{title}</Typography>
          <Typography variant='subtitle1' color="gray" className='text-sm'>{subtitle}</Typography>
        </div>
        <KeyboardArrowRightIcon className='absolute right-10' />
      </Card>
    </Link>
  )
}

export default SelectDashboardCard;
