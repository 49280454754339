import { Card, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

type Props = {
  title: string
  subtitle: string
}

const SigninCards = ({ title, subtitle }: Props) => {
  return (
    <Link to={`/auth/into_${title}`}>
      <Card className='flex p-3 transition-all duration-300 justify-between items-center h-32 w-72 hover:border-[#FF7D58] cursor-pointer border-[1px] border-pink-200'>
        <div className='flex flex-col'>
          <Typography variant='h6' className='font-bold text-base mb-1'>{title}</Typography>
          <Typography variant='subtitle2' color="gray" className='text-xs'>{subtitle}</Typography>
        </div>
        <KeyboardArrowRightIcon />
      </Card>
    </Link>
  )
}

export default SigninCards
