import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';
import { useEffect, useState } from 'react';
import { customBaseUrl, httpGet } from '../../api/https';
import { formatMoney } from '../../utils/formatter';

type Props = {}


export const data = [
  ["Year", "referalls"],
  ["2021", 1000],
  ["2022", 1170],
  ["2023", 660],
  ["2024", 1030],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};
interface Overall {
  
    "totalTransactionCommission": number  ,
    "totalSuccessTransactionCommission": number,
    "totalFailedTransactionCommission": number,
    "totalReferralCount": number,
    "totalReferredUserCount": number,
    "totalSignUpCommissionCount": number,
    "totalWithdrawalCommissionCount": number,
    "totalTransferCommissionCount": number,
    "totalAirtimeTopUpCommissionCount": number,
    "totalDataTopUpCommissionCount": number,
    "totalCableCommissionCount": number,
    "totalUtilityCommissionCount": number,
    "totalBettingCommissionCount": number,
    "totalBillsPaymentAggregatorCommissionCount": number,
    "totalWithdrawalAggregatorCommissionCount": number,
    "totalSuccessSignUpCommissionCount": number,
    "totalSuccessWithdrawalCommissionCount": number,
    "totalSuccessTransferCommissionCount": number,
    "totalSuccessAirtimeTopUpCommissionCount": number,
    "totalSuccessDataTopUpCommissionCount": number,
    "totalSuccessCableCommissionCount": number,
    "totalSuccessUtilityCommissionCount": number,
    "totalSuccessBettingCommissionCount": number,
    "totalSuccessBillsPaymentAggregatorCommissionCount": number,
    "totalSuccessWithdrawalAggregatorCommissionCount": number,
    "totalFailedSignUpCommissionCount": number,
    "totalFailedWithdrawalCommissionCount": number,
    "totalFailedTransferCommissionCount": number,
    "totalFailedAirtimeTopUpCommissionCount": number,
    "totalFailedDataTopUpCommissionCount": number,
    "totalFailedCableCommissionCount": number,
    "totalFailedUtilityCommissionCount": number,
    "totalFailedBettingCommissionCount": number,
    "totalFailedBillsPaymentAggregatorCommissionCount": number,
    "totalFailedWithdrawalAggregatorCommissionCount": number,
    "totalSignUpCommission": number,
    "totalWithdrawalCommission": number,
    "totalTransferCommission": number,
    "totalAirtimeTopUpCommission": number,
    "totalDataTopUpCommission": number,
    "totalCableCommission": number,
    "totalUtilityCommission": number,
    "totalBettingCommission": number,
    "totalBillsPaymentAggregatorCommission": number,
    "totalWithdrawalAggregatorCommission": number,
    "totalWayaRevenue": number,
    "totalSuccessDisbursed": number,
    "totalFailedDisbursed": number,
    "totalReferralDisbursed": number
}

const initial = {
  "totalTransactionCommission": 540,
  "totalSuccessTransactionCommission": 0,
  "totalFailedTransactionCommission": 540,
  "totalReferralCount": 19,
  "totalReferredUserCount": 186,
  "totalSignUpCommissionCount": 540,
  "totalWithdrawalCommissionCount": 0,
  "totalTransferCommissionCount": 0,
  "totalAirtimeTopUpCommissionCount": 0,
  "totalDataTopUpCommissionCount": 0,
  "totalCableCommissionCount": 0,
  "totalUtilityCommissionCount": 0,
  "totalBettingCommissionCount": 0,
  "totalBillsPaymentAggregatorCommissionCount": 0,
  "totalWithdrawalAggregatorCommissionCount": 0,
  "totalSuccessSignUpCommissionCount": 0,
  "totalSuccessWithdrawalCommissionCount": 0,
  "totalSuccessTransferCommissionCount": 0,
  "totalSuccessAirtimeTopUpCommissionCount": 0,
  "totalSuccessDataTopUpCommissionCount": 0,
  "totalSuccessCableCommissionCount": 0,
  "totalSuccessUtilityCommissionCount": 0,
  "totalSuccessBettingCommissionCount": 0,
  "totalSuccessBillsPaymentAggregatorCommissionCount": 0,
  "totalSuccessWithdrawalAggregatorCommissionCount": 0,
  "totalFailedSignUpCommissionCount": 0,
  "totalFailedWithdrawalCommissionCount": 0,
  "totalFailedTransferCommissionCount": 0,
  "totalFailedAirtimeTopUpCommissionCount": 0,
  "totalFailedDataTopUpCommissionCount": 0,
  "totalFailedCableCommissionCount": 0,
  "totalFailedUtilityCommissionCount": 0,
  "totalFailedBettingCommissionCount": 0,
  "totalFailedBillsPaymentAggregatorCommissionCount": 0,
  "totalFailedWithdrawalAggregatorCommissionCount": 0,
  "totalSignUpCommission": 0,
  "totalWithdrawalCommission": 0,
  "totalTransferCommission": 0,
  "totalAirtimeTopUpCommission": 0,
  "totalDataTopUpCommission": 0,
  "totalCableCommission": 0,
  "totalUtilityCommission": 0,
  "totalBettingCommission": 0,
  "totalBillsPaymentAggregatorCommission": 0,
  "totalWithdrawalAggregatorCommission": 0,
  "totalWayaRevenue": 0,
  "totalSuccessDisbursed": 0,
  "totalFailedDisbursed": 0,
  "totalReferralDisbursed": 0
}

const Referral = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [intervals, setInterval] = useState('overall');
  const [overallAnalysis, setOverall] = useState<Overall>(initial);
  const fetchOverview2 = async () => {
    setIsLoading(true);
    const res = await httpGet(`${customBaseUrl.baseUrl}/reports/referral/admin/analysis`,'',false);
    if (res?.status) {
      //setFiltered(response.data.content)
      setIsLoading(false)
      setOverall(res.data);
    } else {
      setOverall(initial);
      console.log(res);
    }
  };
 
  useEffect(() => {
    // fetchOverview();
    fetchOverview2();
  }, []);
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
      <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='total value of referral payment' isLoading={isLoading} value={formatMoney(overallAnalysis.totalReferralDisbursed.toString())} percent="+36%" />
        <DashboardCards title='total count of referral payment' isLoading={isLoading} value={formatMoney(overallAnalysis.totalSuccessDisbursed.toString())} percent="-36%" />
        <DashboardCards title='total count of referral' isLoading={isLoading} value={formatMoney(overallAnalysis.totalReferralCount.toString())} percent="+36%" />
      </div>

      {/* <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      /> */}
    </div>
  )
}

export default Referral
