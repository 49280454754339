import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
import GoBack from "../../components/navigation/goBack";
import {
  Form,
  Formik,
  FormikValues,
  FormikContext,
  useFormikContext,
} from "formik";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as yup from "yup";
import DatePickerInput from "../../components/forms/datePickerInput";
import SelectInput from "../../components/forms/selectInput";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { today } from "../../helpers/formats";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { ACTColumns, GCPColumns } from "../../helpers/data";
import { customBaseUrl, httpPost } from "../../api/https";
import Swal from "sweetalert2";

type Props = {};

const GENERATE_REPORT_URL = `${customBaseUrl.baseUrl}/reports/query`;
const REPORT_LIST_URL = `${customBaseUrl.baseUrl}/reports/list`
  //  "https://services.staging.wayabank.ng/statement-service/reports/list";

const GenerateReport = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const [reports, setReports] = useState<any[]>([]);
  const [reportTypeValue, setReportTypeValue] = useState();
  const [showReport, setShowReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportList, setReportList] = useState<
    {
      id: string;
      report: string;
    }[]
  >();

  const handleSubmit = async ({
    filter,
    fromDate,
    reportType,
    toDate,
    displayReport,
    email,
  }: FormikValues) => {
    setReportTypeValue(reportType);
    setIsLoading(true);
    // let report;
    // if (displayReport === 'Email') {
    const  report = await httpPost(`${customBaseUrl.baseUrl}/reports/fetch`, {
        filter,
        fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
        reportType,
        recipientEmail: email,
        toDate: dayjs(toDate).format("YYYY-MM-DD"),
      }, false);
    // } else {
    //   report = await generateReport({
    //     filter,
    //     fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
    //     reportType,
    //     toDate: dayjs(toDate).format("YYYY-MM-DD"),
    //   });
    // }
    
    // if (report.status && displayReport === 'Table') {
    //   setShowReport(true);
    //   setReports(report.data);
    //   setIsLoading(false);
    // } else 
    if (report.success) {
      setShowReport(false);
      setIsLoading(false);
      Swal.fire('Done', report?.message, 'success')
    } else {
      setIsLoading(false);
      Swal.fire('Oops!', report?.message, 'error')
      setReports([]);
    }
    setIsLoading(false);
  };

  const getReportList = async () => {
    try {
      const response = await axiosPrivate.get(REPORT_LIST_URL);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const generateReport = async ({
    filter,
    fromDate,
    reportType,
    toDate,
  }: {
    filter: string;
    fromDate: string;
    reportType: string;
    toDate: string;
  }) => {
    try {
      const response = await axiosPrivate.post(GENERATE_REPORT_URL, {
        filter,
        fromDate,
        reportType,
        toDate,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getReport = async () => {
      const list = await getReportList();
      setReportList(list);
    };
    getReport();
  }, []);

  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const initialReportValues = {
    fromDate: dayjs(oneWeekAgo),
    toDate: dayjs(today),
    filterDate: "",
    reportType: "",
    displayReport: "",
    email: "",
  };

  const reportValidationSchema = yup.object().shape({
    fromDate: yup.string(),
    toDate: yup.string(),
    filterDate: yup.string(),
    reportType: yup.string(),
    displayReport: yup.string(),
  });

  return reports?.length == 0 ? (
    <div className="relative">
      <GoBack />
      <div className="flex flex-col justify-center pt-20 tems-center text-center">
        <Typography variant="h5" className="font-semibold">
          Generate Report
        </Typography>
        <Typography variant="subtitle2" color="gray">
          Generate report for any of the businessl
        </Typography>
      </div>
      {isLoading ?
               <div style={{ display: 'flex', justifyContent:'center', textAlign:'center' }}>

         <Box sx={{ display: 'flex', textAlign:'center' }}>
         <CircularProgress />
       </Box> 
       </div>

       : 

      <Formik
        initialValues={initialReportValues}
        validationSchema={reportValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleBlur, handleChange, touched, errors }) => (
          <Form>
            <div className="grid gap-8 mt-10 grid-cols-2 w-1/2 mx-auto">
              <DatePickerInput
                label="Start Date"
                defaultValue={values.fromDate}
                name="fromDate"
              />
              <DatePickerInput
                label="End Date"
                defaultValue={values.toDate}
                name="toDate"
              />
              <SelectInput label="Filter date" name="filterDate" />
              <SelectInput
                options={reportList ? reportList : []}
                label="Report type"
                name="reportType"
              />
              {/* <SelectInput label="Display report" name="displayReport" 
                options={[{id: '0', report: 'Email'}, {id:'1', report: 'Table'}]} 
              /> */}
              {/* {values.displayReport === 'Email' ? */}
              <TextField
                label="Email address"
                name='email'
                className='w-full'
                placeholder='Enter work email address'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                color='warning'
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              /> 
              {/* : ''} */}
              <div className="col-span-2 flex justify-end items-center gap-5">
                <button
                  type="submit"
                  className="grid-span-2 flex items-center justify-center transition-all duration-300 col-span-2 py-3 w-1/4 float-right text-white hover:border-[#FF7D58] border-[1px] border-transparent bg-[#FF7D58] hover:bg-transparent hover:text-[#FF7D58] mt-4 rounded-md"
                >
                  Generate report
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>}
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="flex items-center justify-end mt-4">
        <button
          type="reset"
          onClick={() => setReports([])}
          className="grid-span-2 flex items-center justify-center transition-all duration-300 col-span-2 py-2 px-4 float-right text-white border-[#FF7D58] border-[1px] bg-[#FF7D58] rounded-md"
        >
          Back
        </button>
        <button
          type="reset"
          className="grid-span-2 ml-2 flex items-center justify-center transition-all duration-300 col-span-2 py-2 px-4 float-right text-white border-[#FF7D58] border-[1px] bg-[#FF7D58] rounded-md"
        >
          Download
        </button>
      </div>
      <DataGrid
        rows={reports ?? []}
        getRowId={(row) =>
          reportTypeValue == "Group Comission Payment" ? row.id : row.created_at
        }
        className="px-4 my-5"
        columns={
          reportTypeValue == "Group Comission Payment" ? GCPColumns : ACTColumns
        }
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </div>
  );
};

export default GenerateReport;
