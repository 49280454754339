import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const columns: GridColDef[] = [
  { field: 'id', headerName: '#', width: 130 },
  { field: 'user', headerName: 'User', width: 180 },
  { field: 'audit', headerName: 'Audit', width: 180 },
  {
    field: 'userWallets',
    headerName: 'User Wallets',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    width: 180,
  },
  {
    field: 'walletHistories',
    headerName: 'Wallet Histories',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 180,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.user || ''} ${params.row.audit || ''}`,
  },
];

const rows = [
  { id: 1, user: 'Snow', audit: 'Jon', userWallets: 35, walletHistories: 35 },
  { id: 2, user: 'Lannister', audit: 'Cersei', userWallets: 42, walletHistories: 35 },
  { id: 3, user: 'Lannister', audit: 'Jaime', userWallets: 45, walletHistories: 35 },
  { id: 4, user: 'Stark', audit: 'Arya', userWallets: 16, walletHistories: 35 },
  { id: 5, user: 'Targaryen', audit: 'Daenerys', userWallets: null, walletHistories: 35 },
  { id: 6, user: 'Melisandre', audit: null, userWallets: 150, walletHistories: 35 },
  { id: 7, user: 'Clifford', audit: 'Ferrara', userWallets: 44, walletHistories: 35 },
  { id: 8, user: 'Frances', audit: 'Rossini', userWallets: 36, walletHistories: 35 },
  { id: 9, user: 'Roxie', audit: 'Harvey', userWallets: 65, walletHistories: 35 },
];

export default function DataTable() {
  return (
    <div style={{ width: '80%', marginTop: 100 }}>

      <div className='w-full flex mb-5 justify-end'>

        <FormControl className='w-1/4 mr-2'>
          <InputLabel id="demo-simple-select-helper-label">Select format</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            label="Select format"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="pdf">pdf</MenuItem>
            <MenuItem value="csv">csv</MenuItem>
            <MenuItem value={22}>Twenty one and a half</MenuItem>
          </Select>
        </FormControl>
        <button onClick={() => { }} className='bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'>Download</button>
      </div>
      <DataGrid
        rows={rows}
        className='px-6'
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </div>
  );
}
