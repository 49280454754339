import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

const Synchronization = (props: Props) => {
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey David Okunlola -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='count of synchronized accounts' value="2500" percent="+36%" />
      </div>

      <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      />
    </div>
  )
}

export default Synchronization
