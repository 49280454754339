import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import { customBaseUrl, httpGet, httpPost } from '../../api/https';
import { formatMoney } from '../../utils/formatter';
import dayjs from 'dayjs';
import { today } from '../../helpers/formats';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  // curveType: "function",
  legend: { position: "bottom" },
};

interface Overall {
  overallAnalysis: {
    countResponse: {countDeposit: number, totalRevenue: number, countWithdrawal: number},
    sumResponse: {totalBalance: number, totalDeposit: number, totalRevenue:number}
},
categoryAnalysis: {
  countResponse: {
    billsCount: string, 
    nipCount: string, 
    outboundExternalCount: string,
    outboundInternalCount: string,
    paystackCount: string,
    quicktellerCount: string

  },
  sumResponse: {
    billsPaymentTrans: number, 
    nipInbountTrans: number, 
    outboundExternalTrans: number, 
    outboundInternalTrans: number, 
    quicketllerPayment: number, 
    totalPaystackTrans:number 
  }
}
}
const initial: Overall = {overallAnalysis: {
  countResponse: {countDeposit: 0, totalRevenue: 0, countWithdrawal: 0},
  sumResponse: { totalBalance: 0, totalDeposit: 0, totalRevenue:0}
},
categoryAnalysis: {
  countResponse: {
    billsCount: "0", 
    nipCount: "0", 
    outboundExternalCount: "0",
    outboundInternalCount: "0",
    paystackCount: "0",
    quicktellerCount: "0"
  },
  sumResponse: {
    billsPaymentTrans: 0, 
    nipInbountTrans: 0, 
    outboundExternalTrans: 0, 
    outboundInternalTrans: 0, 
    quicketllerPayment:0, 
    totalPaystackTrans: 0
  }
}
}
const NIP = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [intervals, setInterval] = useState('overall');
  const [overallAnalysis, setOverall] = useState<Overall>(initial);
  const [trenData, setTrenData] = useState<any>([["Year", "Inbound", "Outbound"]]);
  const [personalInward, setPersonalInward] = useState<any>([]);
  const [businessInward, setBusinessInward] = useState<any>([]);
  const [personalOutward, setPersonalOutward] = useState<any>([]);
  const [businessOutward, setBusinessOutward] = useState<any>([]);


  const fetchOverview = async () => {
    const res = await httpGet(`${customBaseUrl.walletUrl}/reports/transaction/overall-based-analysis`,'',false);
    if (res?.status) {
      //setFiltered(response.data.content)
      setIsLoading(false)
      setOverall(res.data);
    } else {
      console.log(res);
    }

  };
  const currentM = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
  const oneYearAgo = new Date(today);
  oneYearAgo.setDate(oneYearAgo.getDate() - 365);

  const fetchOverview2 = async () => {
    setIsLoading(true);
    const res = await httpGet(`${customBaseUrl.walletUrl}/api/v1/wallet/transaction/analysis?fromdate=${
      intervals === 'week' 
      ? dayjs(oneWeekAgo).format("YYYY-MM-DD") 
      : intervals === 'month' 
      ? dayjs(oneMonthAgo).format("YYYY-MM-DD") 
      : intervals === 'year' 
      ? dayjs(oneYearAgo).format("YYYY-MM-DD")
      : intervals === 'overall' 
      ? "2000-01-01"
      : dayjs(today).format("YYYY-MM-DD")
    }&todate=${dayjs(new Date(today).setDate(new Date(today).getDate() + 1)).format("YYYY-MM-DD")}`,'',false);
    if (res?.status) {
      //setFiltered(response.data.content)
      const fil = res.data.filter((e: any) => e.StatisticType === 'Total')[0];
      setIsLoading(false)
      setOverall(res.data);
    } else {
      setOverall(initial);
      console.log(res);
    }
  };

  const fetchOverviewPersonal = async () => {
    const data = {
      fromDate: intervals === 'week' 
      ? dayjs(oneWeekAgo).format("YYYY-MM-DD") 
      : intervals === 'month' 
      ? dayjs(oneMonthAgo).format("YYYY-MM-DD") 
      : intervals === 'year' 
      ? dayjs(oneYearAgo).format("YYYY-MM-DD")
      : intervals === 'overall' 
      ? "2000-01-01"
      : dayjs(today).format("YYYY-MM-DD"),
      reportType: "NipInwardPersonalTransactionAnalysis",
      toDate: dayjs(new Date(today).setDate(new Date(today).getDate() + 1)).format("YYYY-MM-DD"),
    }
    setIsLoading(true);
    const res = await httpPost(`${customBaseUrl.baseUrl}/reports/query`, data, false);
    if (res?.status) {
      //setFiltered(response.data.content)
      const fil = res.data.filter((e: any) => e.StatisticType === 'Total')[0];
      setIsLoading(false)
      setPersonalInward(fil);
    } else {
      setOverall(initial);
      console.log(res);
    }
  };
  const fetchOverviewBusiness = async () => {
    const data = {
      fromDate: intervals === 'week' 
      ? dayjs(oneWeekAgo).format("YYYY-MM-DD") 
      : intervals === 'month' 
      ? dayjs(oneMonthAgo).format("YYYY-MM-DD") 
      : intervals === 'year' 
      ? dayjs(oneYearAgo).format("YYYY-MM-DD")
      : intervals === 'overall' 
      ? "2000-01-01"
      : dayjs(today).format("YYYY-MM-DD"),
      reportType: "NipInwardBusinessTransactionAnalysis",
      toDate: dayjs(new Date(today).setDate(new Date(today).getDate() + 1)).format("YYYY-MM-DD"),
    }
    setIsLoading(true);
    const res = await httpPost(`${customBaseUrl.baseUrl}/reports/query`, data, false);
    if (res?.status) {
      //setFiltered(response.data.content)
      const fil = res.data.filter((e: any) => e.StatisticType === 'Total')[0];
      setIsLoading(false)
      setBusinessInward(fil);
    } else {
      setOverall(initial);
      console.log(res);
    }
  };
  const fetchOutOverviewPersonal = async () => {
    const data = {
      fromDate: intervals === 'week' 
      ? dayjs(oneWeekAgo).format("YYYY-MM-DD") 
      : intervals === 'month' 
      ? dayjs(oneMonthAgo).format("YYYY-MM-DD") 
      : intervals === 'year' 
      ? dayjs(oneYearAgo).format("YYYY-MM-DD")
      : intervals === 'overall' 
      ? "2000-01-01"
      : dayjs(today).format("YYYY-MM-DD"),
      reportType: "NipOutwardPersonalTransactionAnalysis",
      toDate: dayjs(new Date(today).setDate(new Date(today).getDate() + 1)).format("YYYY-MM-DD"),
    }
    setIsLoading(true);
    const res = await httpPost(`${customBaseUrl.baseUrl}/reports/query`, data, false);
    if (res?.status) {
      //setFiltered(response.data.content)
      const fil = res.data.filter((e: any) => e.StatisticType === 'Total')[0];
      setIsLoading(false)
      setPersonalOutward(fil);
    } else {
      setOverall(initial);
      console.log(res);
    }
  };
  const fetchOutOverviewBusiness = async () => {
    const data = {
      fromDate: intervals === 'week' 
      ? dayjs(oneWeekAgo).format("YYYY-MM-DD") 
      : intervals === 'month' 
      ? dayjs(oneMonthAgo).format("YYYY-MM-DD") 
      : intervals === 'year' 
      ? dayjs(oneYearAgo).format("YYYY-MM-DD")
      : intervals === 'overall' 
      ? "2000-01-01"
      : dayjs(today).format("YYYY-MM-DD"),
      reportType: "NipOutwardBusinessTransactionAnalysis",
      toDate: dayjs(new Date(today).setDate(new Date(today).getDate() + 1)).format("YYYY-MM-DD"),
    }
    setIsLoading(true);
    const res = await httpPost(`${customBaseUrl.baseUrl}/reports/query`, data, false);
    if (res?.status) {
      //setFiltered(response.data.content)
      const fil = res.data.filter((e: any) => e.StatisticType === 'Total')[0];
      setIsLoading(false)
      setBusinessOutward(fil);
    } else {
      setOverall(initial);
      console.log(res);
    }
  };

  const fetchTrend = async () => {
    const m = currentM +1;
    const res1 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m}-1&todate=${currentYear}-${m +1}-1`,'',false);
    const res2 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m - 1}-1&todate=${currentYear}-${m}-1`,'',false);
    const res3 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m-2}-1&todate=${currentYear}-${m-1}-1`,'',false);
    const res4 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m-3}-1&todate=${currentYear}-${m-2}-1`,'',false);

    setTrenData([...trenData,
      [new Date(2009, currentM-3, 10).toLocaleString('default', { month: 'long' }), res4?.data?.categoryAnalysis?.sumResponse?.outboundInternalTrans ?? 0, res4?.data?.categoryAnalysis?.sumResponse?.outboundExternalTrans ?? 0],
      [new Date(2009, currentM-2, 10).toLocaleString('default', { month: 'long' }), res3?.data?.categoryAnalysis?.sumResponse?.outboundInternalTrans ?? 0, res3?.data?.categoryAnalysis?.sumResponse?.outboundExternalTrans ?? 0],
      [new Date(2009, currentM-1, 10).toLocaleString('default', { month: 'long' }), res2?.data?.categoryAnalysis?.sumResponse?.outboundInternalTrans ?? 0, res2?.data?.categoryAnalysis?.sumResponse?.outboundExternalTrans ?? 0],
      [new Date(2009, currentM, 10).toLocaleString('default', { month: 'long' }), res1?.data?.categoryAnalysis?.sumResponse?.outboundInternalTrans ?? 0, res1?.data?.categoryAnalysis?.sumResponse?.outboundExternalTrans ?? 0],

    ])

  };
  useEffect(() => {
    // fetchOverview2();
    fetchOverviewPersonal();
    fetchOverviewBusiness();
    fetchOutOverviewBusiness();
    fetchOutOverviewPersonal();
  }, [intervals]);
  useEffect(() => {
    fetchTrend();
  }, []);
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div>
        <input 
            type="radio"
            name="interval" 
            value="overall"
            checked={intervals === 'overall'}
            onChange={(e) => setInterval(e.target.value)} className="mx-2"/>
          overall
          <input 
            type="radio"
            name="interval" 
            value="today"
            checked={intervals === 'today'}
            onChange={(e) => setInterval(e.target.value)} className="mx-2"/>
          Today
          <input 
            type="radio" 
            name="interval" 
            value="week" 
            className="mx-2"
            checked={intervals === 'week'}
            onChange={(e) => setInterval(e.target.value)}
            />A Week Ago
          <input 
            type="radio" 
            name="interval" 
            value="month"
            className="mx-2"
            checked={intervals === 'month'}
            onChange={(e) => setInterval(e.target.value)}
            />A Month Ago
          <input
           type="radio"
           name="interval" 
           value="year" 
           className="mx-2"
           checked={intervals === 'year'}
           onChange={(e) => setInterval(e.target.value)}
           />A Year Ago
        </div>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='Total value nip inward Personal' value={new Intl.NumberFormat().format(personalInward.totalvalue ?? 0)}
          percent="+36%"
          isLoading={isLoading}
          />
          <DashboardCards title='Total count nip Inward Personal' value={new Intl.NumberFormat().format(personalInward.totalvolume ?? 0)} percent="-36%" 
          isLoading={isLoading}
        />
        <DashboardCards title='Total value nip outward Personal' value={new Intl.NumberFormat().format(personalOutward.totalvalue ?? 0)} percent="-36%" 
          isLoading={isLoading}
        />       
        <DashboardCards title='Total count nip outward Personal' value={new Intl.NumberFormat().format(personalOutward.totalvolume ?? 0)} percent="-36%" 
          isLoading={isLoading}
        />

        <DashboardCards title='Total value nip inward Business' value={new Intl.NumberFormat().format(businessInward.totalvalue ?? 0)}
          percent="+36%"
          isLoading={isLoading}
          />
          <DashboardCards title='Total count nip Inward Business' value={new Intl.NumberFormat().format(businessInward.totalvolume ?? 0)} percent="-36%" 
          isLoading={isLoading}
        />
        <DashboardCards title='Total value nip outward Business' value={new Intl.NumberFormat().format(businessOutward.totalvalue ?? 0)} percent="-36%" 
          isLoading={isLoading}
        />
        
        <DashboardCards title='Total count nip outward Business' value={new Intl.NumberFormat().format(businessOutward.totalvolume ?? 0)} percent="-36%" 
          isLoading={isLoading}
        />
      </div>
      <Chart
        chartType="LineChart"
        className='mt-10'
        data={trenData}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      />

    </div>
  )
}

export default NIP
