import { Typography } from '@mui/material'
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import StatsCard from '../../components/cards/statsCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { customBaseUrl, httpGet, httpPost } from '../../api/https';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../utils/formatter';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};
interface Overall {
  overallAnalysis: {
    countResponse: {countDeposit: number, totalRevenue: number, countWithdrawal: number},
    sumResponse: {totalBalance: number, totalDeposit: number, totalRevenue:number}
},
categoryAnalysis: {
  countResponse: {
    billsCount: string, 
    nipCount: string, 
    outboundExternalCount: string,
    outboundInternalCount: string,
    paystackCount: string,
    quicktellerCount: string

  },
  sumResponse: {
    billsPaymentTrans: number, 
    nipInbountTrans: number, 
    outboundExternalTrans: number, 
    outboundInternalTrans: number, 
    quicketllerPayment: number, 
    totalPaystackTrans:number 
  }
}
}
const User = (props: Props) => {
  const [totalCorporateUsers,setTotalCorporateUsers] = useState(0)
  const [totalIndividualUsers,setTotalIndividualUsers] = useState(0)
  const [activeUsers, setTotalActiveUsers] = useState(0);
  const [inactiveUsers, setTotalInactiveUsers] = useState(0);
  const [blockUsers, setTotalBlockUsers] = useState(0);
  const [overallAnalysis, setOverall] = useState<Overall>({overallAnalysis: {
    countResponse: {countDeposit: 0, totalRevenue: 0, countWithdrawal: 0},
    sumResponse: { totalBalance: 0, totalDeposit: 0, totalRevenue:0}
  },
  categoryAnalysis: {
    countResponse: {
      billsCount: "0", 
      nipCount: "0", 
      outboundExternalCount: "0",
      outboundInternalCount: "0",
      paystackCount: "0",
      quicktellerCount: "0"
    },
    sumResponse: {
      billsPaymentTrans: 0, 
      nipInbountTrans: 0, 
      outboundExternalTrans: 0, 
      outboundInternalTrans: 0, 
      quicketllerPayment:0, 
      totalPaystackTrans: 0
    }
  }
});
  const [categoryAnalysis, setCategoryAnalysis] = useState([]);
  const [totalTransaction, setTotalTransaction] = useState(0)
  const [totalRegUsers, setTotalRegisteredUsers] = useState(0)
  const [activePersonalUsers, setActivePersonalUsers] = useState(0)
  const [inActivePersonalUsers, setInActivePersonalUsers] = useState(0)
  const [activeCorporate, setActiveCorporateUsers] = useState(0)
  const [inactiveCorporate, setInActiveCorporateUsers] = useState(0)
  const [isLoading, setIsLoading] = useState(true);


  const getUserStat = async () => {
    setIsLoading(true);
    try {
      const res = await httpGet(`${customBaseUrl.baseUrl}/reports/users-analysis`,'', false)
      if (res.status) {
        setIsLoading(false);
        setTotalCorporateUsers(res?.data?.coorporateUsers);
        setTotalIndividualUsers(res?.data?.personalUsers);
        setTotalRegisteredUsers(res?.data?.registeredUsers);
        setTotalActiveUsers(res?.data?.activeUsers);
        setActivePersonalUsers(res?.data?.activePersonal);
        setInActivePersonalUsers(res?.data?.inactivePersonal);
        setTotalInactiveUsers(res?.data?.inactiveUsers);
        setActiveCorporateUsers(res?.data?.activeCorporate);
        setInActiveCorporateUsers(res?.data?.inactiveCorporate);
        setTotalBlockUsers(res?.data?.blockedUsers);
      }
    } catch (error) {
      setIsLoading(true);
      console.log(error)
    }
  }
  const fetchOverview = async () => {
    const res = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/overall-based-analysis`,'',false);
    if (res?.status) {
      //setFiltered(response.data.content)
      setOverall(res.data);
    } else {
      console.log(res);
    }

  };

  const tranChannels: {
    title: string
    value: string
    percent: string
  }[] = [
      {
        title: "Bills Payment",
        value: formatMoney(overallAnalysis?.categoryAnalysis?.sumResponse?.billsPaymentTrans.toString()),
        percent: "+25"
      },
      {
        title: "Total Outboun External Transfer",
        value: formatMoney(overallAnalysis?.categoryAnalysis?.sumResponse?.outboundExternalTrans.toString()),
        percent: "+25"
      },
      {
        title: "Total Outbound Internal Transfer",
        value: formatMoney(overallAnalysis?.categoryAnalysis?.sumResponse?.outboundInternalTrans.toString()),
        percent: "+25"
      },
      {
        title: "Total Inbound Transfer - NIP",
        value: formatMoney(overallAnalysis?.categoryAnalysis?.sumResponse?.nipInbountTrans.toString()),
        percent: "+25"
      },
      {
        title: "Total Inbound Transfer - Paystack'",
        value: formatMoney(overallAnalysis?.categoryAnalysis?.sumResponse?.totalPaystackTrans.toString()),
        percent: "+25"
      },
    ];
    const tranChannelsCount: {
      title: string
      value: string
      percent: string
    }[] = [
        {
          title: "Bills Payment",
          value: overallAnalysis?.categoryAnalysis?.countResponse?.quicktellerCount,
          percent: "+25"
        },
        {
          title: "Total Outboun External Transfer",
          value: overallAnalysis?.categoryAnalysis?.countResponse?.outboundExternalCount,
          percent: "+25"
        },
        {
          title: "Total Outbound Internal Transfer",
          value: overallAnalysis?.categoryAnalysis?.countResponse?.outboundInternalCount,
          percent: "+25"
        },
        {
          title: "Total Inbound Transfer - NIP",
          value: overallAnalysis?.categoryAnalysis?.countResponse?.nipCount,
          percent: "+25"
        },
        {
          title: "Total Inbound Transfer - Paystack'",
          value: overallAnalysis?.categoryAnalysis?.countResponse?.paystackCount,
          percent: "+25"
        },
      ];

  useEffect(() => {
    getUserStat();
    fetchOverview();
  }, []);

  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
          {/* <Link to="/user/add_user" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><PersonAddOutlinedIcon className='mr-2 text-xl' />Add a user</Link> */}
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='Number of users (personal)' value={totalIndividualUsers.toString()}
          isLoading={isLoading}
          percent="+39%"
        />
        <DashboardCards title='Number of active users (personal)' value={activePersonalUsers.toString()}
          percent="-36%"
          isLoading={isLoading}
        />
        <DashboardCards title='Number of inactive users (personal)' value={inActivePersonalUsers.toString()}
          percent="+36%"
          isLoading={isLoading}
         />
        <DashboardCards title='Total customers' value={totalRegUsers.toString()}
          percent="+36%"
          isLoading={isLoading}
        />
        <DashboardCards title='Number of users (business)' value={totalCorporateUsers.toString()} 
          percent="+36%"
          isLoading={isLoading}
        />
        <DashboardCards title='Number of active users (business)' value={activeCorporate.toString()} 
          percent="-36%"
          isLoading={isLoading}
        />
        <DashboardCards title='Number of inactive users (business)' value={inactiveCorporate.toString()} 
          percent="+36%"
          isLoading={isLoading}
        />
        <DashboardCards title='Total customers' value={totalRegUsers.toString()} 
          percent="+36%"
          isLoading={isLoading}
        />
      </div>
      {/* <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="70%"
        height="300px"
        legendToggle
      /> */}
      <div className='flex gap-5'>
        <StatsCard title='value of deposit per channel' data={tranChannels}  value="7000000" percent='+25' />
        <StatsCard title='count of deposits per channel' data={tranChannelsCount} value="90000" percent='+25' />
      </div>

    </div>
  )
}

export default User
