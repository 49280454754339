import { Outlet, useNavigate } from 'react-router-dom'
import GramSidebar from '../components/layout/gramSidebar'
import { useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import Navbar from '../components/layout/navbar'

type Props = {}

const AnonymousWayaGram = (props: Props) => {

  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate("/select_options")
    }
  }, [auth])

  return (
    <>
      <Navbar />
      <div className='flex bg-gray-accent'>
        <GramSidebar />
        <div className='w-full pb-14 px-14'>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default AnonymousWayaGram
