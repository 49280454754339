import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import StatsCard from '../../components/cards/statsCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TuneIcon from '@mui/icons-material/Tune';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

const WayaquickUser = (props: Props) => {
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey David Okunlola -</Typography>
        <div className='flex gap-5'>
          <button
            className='flex items-center justify-center transition-all duration-300 px-3 py-1 float-right hover:text-white border-[#FF7D58] border-[1px] hover:bg-[#FF7D58] bg-transparent text-[#FF7D58] rounded-2xl'
          >
            <TuneIcon className='mr-2' />
            filter
          </button>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='Total number of users' value="2500" percent="+36%" />
        <DashboardCards title='Total number of active users' value="1800" percent="-36%" />
        <DashboardCards title='Total number of inactive users' value="350" percent="+36%" />
        <DashboardCards title='Total number of users (business)' value="33493" percent="+36%" />
        <DashboardCards title='Total number of active users (business)' value="2500" percent="+36%" />
        <DashboardCards title='Total number of inactive users (business)' value="1800" percent="-36%" />
        <DashboardCards title='Total number of users (aggregators)' value="350" percent="+36%" />
        <DashboardCards title='Total number of active users (aggregators)' value="33493" percent="+36%" />
        <DashboardCards title='Total number of inactive users (aggregators)' value="33493" percent="+36%" />
        <DashboardCards title='Total number of users (agent)' value="33493" percent="+36%" />
        <DashboardCards title='Total number of active users (agent)' value="33493" percent="+36%" />
        <DashboardCards title='Total number of inactive users (agent)' value="33493" percent="+36%" />
        <DashboardCards title='Total number of users each of the business user category' value="350" percent="+36%" />
        <DashboardCards title='Total number of active users each of the business user category' value="350" percent="+36%" />
        <DashboardCards title='Total number of inactive users each of the business user category' value="350" percent="+36%" />
      </div>
      <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="70%"
        height="300px"
        legendToggle
      />

    </div>
  )
}

export default WayaquickUser
