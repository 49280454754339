import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useField } from "formik";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { Input } from "@mui/material";

type Props = {
  name: string;
  label: string;
  defaultValue?: Dayjs;
};

const DatePickerInput = ({ label, name, defaultValue, ...props }: Props) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          className="w-full rounded-lg"
          {...props}
          value={undefined}
          defaultValue={defaultValue}
          label={label}
          onChange={(value) => helpers.setValue(value)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DatePickerInput;
