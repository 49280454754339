import { Typography } from '@mui/material'
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import StatsCard from '../../components/cards/statsCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import { customBaseUrl, httpGet } from '../../api/https';
import { formatMoney } from '../../utils/formatter';
import dayjs from 'dayjs';
import { today } from '../../helpers/formats';

type Props = {}


export const data = [
  ["Year", "Sales"],
  ["2004", 1000],
  ["2005", 1170,],
  ["2006", 660],
  ["2007", 1030],
];

export const options = {
  title: "Company Performance",
  //  curveType: "function",
  legend: { position: "bottom" },
};

interface Overall {
  overallAnalysis: {
    countResponse: {countDeposit: number, totalRevenue: number, countWithdrawal: number},
    sumResponse: {totalBalance: number, totalDeposit: number, totalRevenue:number}
},
categoryAnalysis: {
  countResponse: {
    billsCount: string, 
    nipCount: string, 
    outboundExternalCount: string,
    outboundInternalCount: string,
    paystackCount: string,
    quicktellerCount: string

  },
  sumResponse: {
    billsPaymentTrans: number, 
    nipInbountTrans: number, 
    outboundExternalTrans: number, 
    outboundInternalTrans: number, 
    quicketllerPayment: number, 
    totalPaystackTrans:number 
  }
}
}
const initial: Overall = {overallAnalysis: {
  countResponse: {countDeposit: 0, totalRevenue: 0, countWithdrawal: 0},
  sumResponse: { totalBalance: 0, totalDeposit: 0, totalRevenue:0}
},
categoryAnalysis: {
  countResponse: {
    billsCount: "0", 
    nipCount: "0", 
    outboundExternalCount: "0",
    outboundInternalCount: "0",
    paystackCount: "0",
    quicktellerCount: "0"
  },
  sumResponse: {
    billsPaymentTrans: 0, 
    nipInbountTrans: 0, 
    outboundExternalTrans: 0, 
    outboundInternalTrans: 0, 
    quicketllerPayment:0, 
    totalPaystackTrans: 0
  }
}
}
const Deposit = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [intervals, setInterval] = useState('overall');
  const [overallAnalysis, setOverall] = useState<Overall>(initial);
  const [trenData, setTrenData] = useState<any>([["Year", "Sales"]]);
  const fetchOverview = async () => {
    const res = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/overall-based-analysis`,'',false);
    if (res?.status) {
      //setFiltered(response.data.content)
      setIsLoading(false)
      setOverall(res.data);
    } else {
      console.log(res);
    }

  };
  const currentM = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
  const oneYearAgo = new Date(today);
  oneYearAgo.setDate(oneYearAgo.getDate() - 365);
  const initialReportValues = {
    fromdate: dayjs(oneWeekAgo),
    toDate: dayjs(today),
    filterdate: "",
    reportType: "",
    displayReport: "",
    email: "",
  };
  const fetchOverview2 = async () => {
    setIsLoading(true);
    const res = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${
      intervals === 'week' 
      ? dayjs(oneWeekAgo).format("YYYY-MM-DD") 
      : intervals === 'month' 
      ? dayjs(oneMonthAgo).format("YYYY-MM-DD") 
      : intervals === 'year' 
      ? dayjs(oneYearAgo).format("YYYY-MM-DD")
      : intervals === 'overall' 
      ? "2000-01-01"
      : dayjs(today).format("YYYY-MM-DD")
    }&todate=${dayjs(new Date(today).setDate(new Date(today).getDate() + 1)).format("YYYY-MM-DD")}`,'',false);
    if (res?.status) {
      //setFiltered(response.data.content)
      setIsLoading(false)
      setOverall(res.data);
    } else {
      setOverall(initial);
      console.log(res);
    }
  };
  let trendData: any = [];

  const fetchTrend = async () => {
    const m = currentM +1;
    const res1 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m}-1&todate=${currentYear}-${m +1}-1`,'',false);
    const res2 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m - 1}-1&todate=${currentYear}-${m}-1`,'',false);
    const res3 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m-2}-1&todate=${currentYear}-${m-1}-1`,'',false);
    const res4 = await httpGet(`${customBaseUrl.baseUrl}/reports/transaction/analysis?fromdate=${currentYear}-${m-3}-1&todate=${currentYear}-${m-2}-1`,'',false);

    // if (res1?.status) {
    //   //setFiltered(response.data.content)  
    //   trendData.push([currentYear, res1?.data?.overallAnalysis?.sumResponse?.totalDeposit])
    //   setTrenData([...trenData, [currentYear, res1?.data?.overallAnalysis?.sumResponse?.totalDeposit]])

    //   //setOverall(res1.data);
    // }
    // if (res2?.status) {
    //   //setFiltered(response.data.content)
    //   console.log({curn:currentYear-1, val: res2?.data?.overallAnalysis?.sumResponse?.totalDeposit})
    //   trendData.push([currentYear - 1, res2?.data?.overallAnalysis?.sumResponse?.totalDeposit])
    //   setTrenData([...trenData, [currentYear-1, res2?.data?.overallAnalysis?.sumResponse?.totalDeposit]])

    //   //setOverall(res1.data);
    //   setTrenData([...trenData, [currentYear - 1, res2?.data?.overallAnalysis?.sumResponse?.totalDeposit]])

    // }
    // if (res3?.status) {
    //   //setFiltered(response.data.content)
    //   setTrenData([...trenData, [currentYear-2, res3?.data?.overallAnalysis?.sumResponse?.totalDeposit]])
    //   console.log({curn:currentYear-2, val: res3?.data?.overallAnalysis?.sumResponse?.totalDeposit})
    //   trendData.push([currentYear-2, res3?.data?.overallAnalysis?.sumResponse?.totalDeposit])
    //   //setOverall(res1.data);
    // }
    // if (res4?.status) {
    //   //setFiltered(response.data.content)
    //   console.log({curn:currentYear-3, val: res4?.data?.overallAnalysis?.sumResponse?.totalDeposit})
    //   setTrenData([...trenData, [currentYear-3, res4?.data?.overallAnalysis?.sumResponse?.totalDeposit]])
      
    //   trendData.push([currentYear-3, res4?.data?.overallAnalysis?.sumResponse?.totalDeposit])
    //   //setOverall(res1.data);
    // }
    setTrenData([...trenData,
      [new Date(2009, currentM-3, 10).toLocaleString('default', { month: 'long' }), res4?.data?.overallAnalysis?.sumResponse?.totalDeposit ?? 0],
      [new Date(2009, currentM-2, 10).toLocaleString('default', { month: 'long' }), res3?.data?.overallAnalysis?.sumResponse?.totalDeposit ?? 0],
      [new Date(2009, currentM-1, 10).toLocaleString('default', { month: 'long' }), res2?.data?.overallAnalysis?.sumResponse?.totalDeposit ?? 0],
      [new Date(2009, currentM, 10).toLocaleString('default', { month: 'long' }), res1?.data?.overallAnalysis?.sumResponse?.totalDeposit ?? 0],

    ])

  };

// https://services.staging.wayabank.ng/temporal-service/api/v1/wallet/transaction/analysis?fromdate=2010-01-01&todate=2024-04-06

  useEffect(() => {
    // fetchOverview();
    fetchOverview2();
    console.log(trenData);
  }, [intervals]);
  useEffect(() => {
    // fetchOverview();
    fetchTrend();
  }, []);
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div>
          <input 
            type="radio"
            name="interval" 
            value="overall"
            checked={intervals === 'overall'}
            onChange={(e) => setInterval(e.target.value)} className="mx-2"/>
          Overall
          <input 
            type="radio"
            name="interval" 
            value="today"
            checked={intervals === 'today'}
            onChange={(e) => setInterval(e.target.value)} className="mx-2"/>
          Today
          <input 
            type="radio" 
            name="interval" 
            value="week" 
            className="mx-2"
            checked={intervals === 'week'}
            onChange={(e) => setInterval(e.target.value)}
            />A Week Ago
          <input 
            type="radio" 
            name="interval" 
            value="month"
            className="mx-2"
            checked={intervals === 'month'}
            onChange={(e) => setInterval(e.target.value)}
            />A Month Ago
          <input
           type="radio"
           name="interval" 
           value="year" 
           className="mx-2"
           checked={intervals === 'year'}
           onChange={(e) => setInterval(e.target.value)}
           />A Year Ago
        </div>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='Total deposit amount' value={formatMoney(overallAnalysis.overallAnalysis.sumResponse.totalDeposit.toString())} 
        percent="+36%"
          isLoading={isLoading}
        />
        <DashboardCards title='Total deposit count' value={formatMoney(overallAnalysis.overallAnalysis.countResponse.countDeposit.toString())} percent="-36%" 
          isLoading={isLoading}
        />
        <DashboardCards title='Total deposit amount (Personal)' value={formatMoney(overallAnalysis.overallAnalysis.sumResponse.totalDeposit.toString())} 
          percent="+36%" 
          isLoading={isLoading}
          />
        <DashboardCards title='Total deposit count (personal)' value={formatMoney(overallAnalysis.overallAnalysis.countResponse.countDeposit.toString())}
          percent="+36%" isLoading={isLoading} />
        <DashboardCards title='Total deposit amount (business)' value={formatMoney(overallAnalysis.overallAnalysis.sumResponse.totalDeposit.toString())} percent="+36%" 
          isLoading={isLoading}
        />
        <DashboardCards title='Total deposit count (business)' value={formatMoney(overallAnalysis.overallAnalysis.countResponse.countDeposit.toString())} percent="-36%"
          isLoading={isLoading}
        />
      </div>
      <Chart
        chartType="LineChart"
        className='mt-10'
        data={trenData}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      />
      <div className='flex gap-5'>
        <StatsCard title='value of deposit per channel' value="7000000" percent='+25' />
        <StatsCard title='count of deposits per channel' value="90000" percent='+25' />
      </div>

    </div>
  )
}

export default Deposit
