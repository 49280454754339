import { createContext, useState, ReactNode, useMemo } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

type IAuthContext = {
  auth: any
  login: (userAuth: any) => void
  logout: () => void
}

type Props = {
  children: ReactNode
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useLocalStorage("auth", null)

  const login = (userAuth: any) => {
    setAuth(userAuth)
  }

  const logout = () => {
    setAuth(null)
  }

  const value = useMemo(() => ({
    auth, login, logout
  }), [auth])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
