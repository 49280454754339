import React from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}

const MainLayout = (props: Props) => {
  return (
    <>
      <div className='absolute top-0 w-full h-14 bg-white border-b-[1px] border-[#F56630]' />
      <Outlet />
    </>
  )
}

export default MainLayout
