import { Radio, Typography } from '@mui/material'
import { Dispatch } from 'react'

type Props = {
  selectedValue: string
  setSelectedValue: Dispatch<string>
  value: string
  subtitle: string
}

const RadioButton = ({ subtitle, selectedValue, setSelectedValue, value }: Props) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  return (
    <div onClick={() => setSelectedValue(value)} className='flex px-3 py-5 cursor-pointer w-full border-primary border-[1px] border-dashed' >
      <div>
        <Typography variant='h6' className='font-semibold' lineHeight={2}>{value}</Typography>
        <Typography variant='subtitle2' className='font-medium text-xs' color="gray">{subtitle}</Typography>
      </div>
      <Radio className='text-primary' {...controlProps(value)} />
    </div>
  )
}

export default RadioButton
