import { Typography } from "@mui/material";
import SelectDashboardCard from "../../components/cards/selectDashboardCard";

const SelectDashboard = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center w-[600px] 2xl:w-[620px] h-[70vh] mx-auto my-auto">
        <Typography
          variant="h3"
          className="font-bold mb-4 text-4xl text-center"
        >
          Choose your sign in option
        </Typography>
        <Typography
          variant="subtitle1"
          color="gray"
          className="text-center text-base"
        >
          Our comprehensive design system offers you an unparalleled range of
          components, sparking creativity and boosting efficiency.
        </Typography>
        <div className="grid grid-cols-1 w-full 2xl:gap-8 lg:gap-6 mt-10">
          <SelectDashboardCard
            title="Report Dashboard"
            subtitle="Click to sign in to access report dashboard"
            link="/select_options"
          />
          <SelectDashboardCard
            title="Tech Support"
            subtitle="Click to sign in to access tech support"
            link="/select_options"
          />
        </div>
      </div>
      <div className="w-[95%] bg-[#FFEFEA] rounded-t-xl h-[30vh] mx-auto flex justify-center items-center flex-col">
        <div className="flex flex-col w-[370px]">
          <Typography
            variant="h4"
            className="text-2xl font-extrabold text-center text-[#FF6634]"
          >
            Report and Analytics Dashboard and Tech Suport
          </Typography>
          <Typography
            variant="subtitle1"
            color="gray"
            className="mt-2 text-center text-sm leading-5"
          >
            Access report, analytics dashboard and tech support to see a
            comprehensive view
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SelectDashboard;
