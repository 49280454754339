import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

type Props = {}

const columns: GridColDef[] = [
  { field: 'id', headerName: '#', width: 130 },
  { field: 'user', headerName: 'User', width: 180 },
  { field: 'audit', headerName: 'Audit', width: 180 },
  {
    field: 'userWallets',
    headerName: 'User Wallets',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    width: 180,
  },
  {
    field: 'walletHistories',
    headerName: 'Wallet Histories',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 180,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.user || ''} ${params.row.audit || ''}`,
  },
];

const rows = [
  { id: 1, user: 'Snow', audit: 'Jon', userWallets: 35, walletHistories: 35 },
  { id: 2, user: 'Lannister', audit: 'Cersei', userWallets: 42, walletHistories: 35 },
  { id: 3, user: 'Lannister', audit: 'Jaime', userWallets: 45, walletHistories: 35 },
  { id: 4, user: 'Stark', audit: 'Arya', userWallets: 16, walletHistories: 35 },
  { id: 5, user: 'Targaryen', audit: 'Daenerys', userWallets: null, walletHistories: 35 },
  { id: 6, user: 'Melisandre', audit: null, userWallets: 150, walletHistories: 35 },
  { id: 7, user: 'Clifford', audit: 'Ferrara', userWallets: 44, walletHistories: 35 },
  { id: 8, user: 'Frances', audit: 'Rossini', userWallets: 36, walletHistories: 35 },
  { id: 9, user: 'Roxie', audit: 'Harvey', userWallets: 65, walletHistories: 35 },
];


const AccountVerificationAndLogin = (props: Props) => {
  return (
    <div className='h-fit bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
        <Typography variant='h5' color="black" className='text-base font-bold'>Hey David Okunlola -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='count of account created successfully' value="2500" percent="+36%" />
        <DashboardCards title='count of account create failure' value="1800" percent="-36%" />
        <DashboardCards title='count of incomplete creation' value="350" percent="+36%" />
        <DashboardCards title='imcomplete document logs' value="33493" percent="+36%" />
        <DashboardCards title='unique customer created' value="350" percent="+36%" />
        <DashboardCards title='accumulated daily session count' value="33493" percent="+36%" />
        <DashboardCards title='count successful login' value="2500" percent="+36%" />
        <DashboardCards title='count unsuccessful login' value="1800" percent="-36%" />
        <DashboardCards title='count failure' value="2500" percent="+36%" />
        <DashboardCards title='downtime logs' value="1800" percent="-36%" />
        <DashboardCards title='regional session traffic' value="2500" percent="+36%" />
        <DashboardCards title='Count Password Reset Made//Forget Password Clicks' value="350" percent="+36%" />
        <DashboardCards title='count successful password reset' value="33493" percent="+36%" />
        <DashboardCards title='password reset failure rate' value="2500" percent="+36%" />
        <DashboardCards title='password reset channels' value="1800" percent="-36%" />
        <DashboardCards title='count email password reset' value="2500" percent="+36%" />
        <DashboardCards title='count sms password reset' value="1800" percent="-36%" />
      </div>

      <DataGrid
        rows={rows}
        className='px-6 my-10'
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </div>
  )
}

export default AccountVerificationAndLogin
