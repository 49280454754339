import { Typography } from '@mui/material'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Link } from 'react-router-dom';

type Props = {}

const CheckMail = (props: Props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  return (
    <div className="flex items-center bg-gray-100 justify-center h-screen font-lato">
      <div className='px-5 border-gray-200 py-7 border-[1px] bg-white rounded-md h-fit flex justify-center items-center w-[500px]'>
        <div className='flex text-center flex-col mb-8 items-center justify-center w-[350px]'>
          <div className='p-5 rounded-full bg-[#FFEFEA] text-[#FF5D2E] mb-2'>
            <ForwardToInboxIcon fontSize='medium' />
          </div>
          <Typography variant='h6' className='font-bold'>Check your mail</Typography>
          <Typography variant='subtitle2' color="gray" className='mt-1 mb-5'>Reset link has been sent to your mail. Follow the instruction to reset your password.</Typography>
          <Link
            to={`/reset_password?email=${email}`}
            type='submit'
            className='text-center transition-all duration-300 col-span-2 py-3 w-full text-white hover:border-[#FF7D58] border-[1px] border-transparent bg-[#FF7D58] hover:bg-transparent hover:text-[#FF7D58] rounded-md'
          >
            Got it
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CheckMail;
