import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

export const ACTColumns: GridColDef[] = [
  { field: "acct_num", headerName: "Account No.", width: 130 },
  { field: "amount", headerName: "Amount", width: 80 },
  { field: "tran_type", headerName: "Type", width: 120 },
  { field: "sender_name", headerName: "Sender", width: 200 },
  { field: "reciever_name", headerName: "Reciever", width: 200 },
  { field: "tran_narrate", headerName: "Narration", width: 220 },
  {
    field: "tran_category",
    headerName: "Category",
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 120,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.status || ""}`,
  },
];

export const GCPColumns: GridColDef[] = [
  { field: "acct_name", headerName: "Account Name", width: 220 },
  { field: "account_no", headerName: "Account No.", width: 130 },
  { field: "product_code", headerName: "Code", width: 80 },
  { field: "product_type", headerName: "Type", width: 80 },
  { field: "email_address", headerName: "Email", width: 230 },
  { field: "description", headerName: "Description", width: 220 },
  { field: "mobile_no", headerName: "Phone", width: 150 },
  {
    field: "nuban_account_no",
    headerName: "Nuban Account #",
    sortable: false,
    width: 120,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.nuban_account_no || ""}`,
  },
];
