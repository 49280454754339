import { InputAdornment, TextField, Typography } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "../../api/axios";
import OTPModal from "../../components/modal/OTPModal";

type Props = {};

const URL = "/password/forgot-password";

const ResetPassword = (props: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpModal, setOtpModal] = useState({
    open: false,
    password: "",
  });
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validateLoginSchema = yup.object().shape({
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .label("confirm password")
      .required("Required")
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const handleSubmit = ({ password }: FormikValues) => {
    setOtpModal({ open: true, password });
  };

  const handleOTPSubmit = async (
    otp: number,
    setIsSubmitting: Dispatch<SetStateAction<boolean>>
  ) => {
    try {
      setIsSubmitting(true);
      await axios.post(
        URL,
        {
          phoneOrEmail: email,
          otp,
          newPassword: otpModal.password,
        },
        {
          headers: {
            "client-id": "WAYABANK",
            "client-type": "PERSONAL",
          },
        }
      );
      navigate("/password_changed");
    } catch (error) {}
    setIsSubmitting(false);
  };

  return (
    <div className="flex items-center bg-gray-100 justify-center h-screen font-lato">
      <OTPModal
        setModal={setOtpModal}
        modal={otpModal}
        handleOTPSubmit={handleOTPSubmit}
      />
      <div className="px-5 border-gray-200 py-9 border-[1px] bg-white rounded-md h-fit w-[400px]">
        <div className="flex text-center flex-col mb-8 items-center justify-center">
          <Typography variant="h5" className="font-bold">
            Reset Password
          </Typography>
          <Typography variant="subtitle2" color="gray" className="mt-1">
            Reset your password. Go ahead and set a new password
          </Typography>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validateLoginSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form>
              <div className="flex flex-col items-center justify-center gap-4">
                <TextField
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="warning"
                  error={!!touched.password && !!errors.password}
                  helperText={!!touched.password && errors.password}
                  InputProps={{
                    className: "cursor-pointer",
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <VisibilityOffIcon
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <VisibilityIcon
                            onClick={() => setShowPassword(true)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <TextField
                  label="Confirm Password"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="warning"
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  helperText={
                    !!touched.confirmPassword && errors.confirmPassword
                  }
                  InputProps={{
                    className: "cursor-pointer",
                    endAdornment: (
                      <InputAdornment position="end">
                        {showConfirmPassword ? (
                          <VisibilityOffIcon
                            onClick={() => setShowConfirmPassword(false)}
                          />
                        ) : (
                          <VisibilityIcon
                            onClick={() => setShowConfirmPassword(true)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <button
                  type="submit"
                  className="text-center transition-all duration-300 col-span-2 py-3 w-full text-white hover:border-[#FF7D58] border-[1px] border-transparent bg-[#FF7D58] hover:bg-transparent hover:text-[#FF7D58] rounded-md"
                >
                  Save changes
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
