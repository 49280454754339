import React from "react";
import Back from "../../components/navigation/back";

type Props = {};

const NotFound = (props: Props) => {
  return (
    <>
      <Back />
      <div className="flex items-center justify-center h-[80vh] w-full">
        404 Not Found !!!
      </div>
    </>
  );
};

export default NotFound;
