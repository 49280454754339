import { InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from "yup"
import { SearchOutlined } from '@mui/icons-material';
import bankLogo from "../../assets/WayaBank.svg"
import groupLogo from "../../assets/wayaGroup-logo.svg"
import gramLogo from "../../assets/wayagram-logo.svg"
import quickLogo from "../../assets/wayaQuick-logo.svg"
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

type Props = {}

const Navbar = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useAuth()
  const open = Boolean(anchorEl);
  const location = useLocation()
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const inputRef = useRef<HTMLInputElement>(null)

  const handleSearch = ({ search }: FormikValues) => {
    console.log(search)
  }

  return (
    <div className='w-full border-b-gray-300 border-b-[1px] flex justify-around p-1.5 items-center'>
      <div className='w-1/6 flex items-center justify-center'>
        <img src={location.pathname.match(/(wayagroup)/gm) ? groupLogo : location.pathname.match(/(wayagram)/gm) ? gramLogo : location.pathname.match(/(wayaquick)/gm) ? quickLogo : bankLogo} className='cursor-pointer' />
      </div>
      <Formik
        initialValues={{ search: "" }}
        validationSchema={yup.object().shape({
          search: yup.string()
        })}
        onSubmit={handleSearch}
      >
        {({
          values,
          setFieldValue,
          handleChange
        }) => (
          <Form className='w-3/5'>
            <TextField
              name='search'
              ref={inputRef}
              variant='outlined'
              size='small'
              placeholder='Type to search'
              value={values.search}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined type="submit" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <ClearIcon className='cursor-pointer' onClick={() => {
                      setFieldValue("search", "");
                      inputRef?.current?.focus();
                    }} />
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </Form>
        )}
      </Formik>
      <div className='flex justify-around items-center w-1/6'>
        <div className='p-2 rounded-full flex items-center justify-center hover:bg-gray-100 cursor-pointer'>
          <NotificationsNoneOutlinedIcon className='text-gray-500 h-6 w-6' />
        </div>
        <div className='flex flex-col justify-center h-4'>
          <Typography variant='subtitle1' fontWeight={600} lineHeight={1.4} color="gray">
            {localStorage.name}
          </Typography>
          <Typography variant='subtitle2' className='text-xs' lineHeight={1} color="gray">Super Admin</Typography>
        </div>
        <img src='/avatar-image.png' onClick={handleClick} alt='avatar' className='cursor-pointer rounded-full w-8 h-8' />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: -10,
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default Navbar; '/home/zanibal/Downloads/WayaBank.svg' 
