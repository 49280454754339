import { Card, Typography } from '@mui/material'
import React from 'react'
import StraightOutlinedIcon from '@mui/icons-material/StraightOutlined';
import { formatMoney } from '../../utils/formatter';

type Props = {
  title: string
  percent: string
  value: string
  data?: {
    title: string
    value: string
    percent: string
  }[]
}

const StatsCard = ({ value, title, percent, data }: Props) => {
  const channels: {
    title: string
    value: string
    percent: string
  }[] = [
      {
        title: "USSD",
        value: "12000000",
        percent: "+25"
      },
      {
        title: "Bank Transfer",
        value: "12000000",
        percent: "+25"
      },
      {
        title: "NIP",
        value: "12000000",
        percent: "+25"
      },
      {
        title: "Wayalink",
        value: "12000000",
        percent: "+25"
      },
    ]
  return (
    <Card variant='outlined' className='w-96 my-10 px-4 pb-2'>
      <Typography variant='subtitle2' color="gray" lineHeight={4} className='tracking-widest uppercase'>{title}</Typography>
      {/* <div className='flex'>
        <span className='text-black text-2xl mr-10 font-bold'>{formatMoney(value)}</span>
        <span className={`${percent.startsWith("+") ? `text-green-500 items-center flex` : `text-red-500 items-center flex`} font-bold`}>{`${percent}`}{percent.startsWith("+") ? <StraightOutlinedIcon className='font-thin' /> : <StraightOutlinedIcon className='text-sm' />}</span>
      </div> */}
      <ul className='divide-y-2 divide-dashed mt-3'>
        {data?.map(({ title, value, percent }, index) => (
          <li key={index} className='py-1 flex justify-between'><span className='w-1/3 font-semibold text-sm'>{title}</span><span className='w-1/3 font-semibold text-sm text-end'>{value}</span>
            {/* <span className={`${percent.startsWith("+") ? `text-green-500` : `text-red-500`} w-1/3 font-semibold text-sm text-end`}>
              {percent}
            </span> */}
          </li>
        ))}
      </ul>
    </Card>
  )
}

export default StatsCard
