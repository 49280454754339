export function formatMoney(amount: string): string {
  // Remove any non-numeric characters from the input string
  const numericString = amount ? amount?.replace(/[^0-9.]/g, '') : "0";

  // Convert the numeric string to a number
  const numericValue = parseFloat(numericString);

  // Check if the input is a valid number
  if (isNaN(numericValue)) {
    throw new Error('Invalid input');
  }

  // Convert the number to a string with two decimal places and commas for thousands separator
  const formattedAmount = numericValue.toLocaleString('en-US');

  return formattedAmount;
}

