import { Typography } from '@mui/material'
import DashboardCards from '../../components/cards/dashboardCards';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chart from 'react-google-charts';
import { useEffect, useState } from 'react';
import { customBaseUrl, httpGet } from '../../api/https';

type Props = {}


export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 0],
];

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};
interface Overall {
  
  "totalTransactionCommission": number  ,
  "totalSuccessTransactionCommission": number,
  "totalFailedTransactionCommission": number,
  "totalReferralCount": number,
  "totalReferredUserCount": number,
  "totalSignUpCommissionCount": number,
  "totalWithdrawalCommissionCount": number,
  "totalTransferCommissionCount": number,
  "totalAirtimeTopUpCommissionCount": number,
  "totalDataTopUpCommissionCount": number,
  "totalCableCommissionCount": number,
  "totalUtilityCommissionCount": number,
  "totalBettingCommissionCount": number,
  "totalBillsPaymentAggregatorCommissionCount": number,
  "totalWithdrawalAggregatorCommissionCount": number,
  "totalSuccessSignUpCommissionCount": number,
  "totalSuccessWithdrawalCommissionCount": number,
  "totalSuccessTransferCommissionCount": number,
  "totalSuccessAirtimeTopUpCommissionCount": number,
  "totalSuccessDataTopUpCommissionCount": number,
  "totalSuccessCableCommissionCount": number,
  "totalSuccessUtilityCommissionCount": number,
  "totalSuccessBettingCommissionCount": number,
  "totalSuccessBillsPaymentAggregatorCommissionCount": number,
  "totalSuccessWithdrawalAggregatorCommissionCount": number,
  "totalFailedSignUpCommissionCount": number,
  "totalFailedWithdrawalCommissionCount": number,
  "totalFailedTransferCommissionCount": number,
  "totalFailedAirtimeTopUpCommissionCount": number,
  "totalFailedDataTopUpCommissionCount": number,
  "totalFailedCableCommissionCount": number,
  "totalFailedUtilityCommissionCount": number,
  "totalFailedBettingCommissionCount": number,
  "totalFailedBillsPaymentAggregatorCommissionCount": number,
  "totalFailedWithdrawalAggregatorCommissionCount": number,
  "totalSignUpCommission": number,
  "totalWithdrawalCommission": number,
  "totalTransferCommission": number,
  "totalAirtimeTopUpCommission": number,
  "totalDataTopUpCommission": number,
  "totalCableCommission": number,
  "totalUtilityCommission": number,
  "totalBettingCommission": number,
  "totalBillsPaymentAggregatorCommission": number,
  "totalWithdrawalAggregatorCommission": number,
  "totalWayaRevenue": number,
  "totalSuccessDisbursed": number,
  "totalFailedDisbursed": number,
  "totalReferralDisbursed": number
}

const initial = {
"totalTransactionCommission": 0,
"totalSuccessTransactionCommission": 0,
"totalFailedTransactionCommission": 0,
"totalReferralCount": 0,
"totalReferredUserCount": 0,
"totalSignUpCommissionCount": 0,
"totalWithdrawalCommissionCount": 0,
"totalTransferCommissionCount": 0,
"totalAirtimeTopUpCommissionCount": 0,
"totalDataTopUpCommissionCount": 0,
"totalCableCommissionCount": 0,
"totalUtilityCommissionCount": 0,
"totalBettingCommissionCount": 0,
"totalBillsPaymentAggregatorCommissionCount": 0,
"totalWithdrawalAggregatorCommissionCount": 0,
"totalSuccessSignUpCommissionCount": 0,
"totalSuccessWithdrawalCommissionCount": 0,
"totalSuccessTransferCommissionCount": 0,
"totalSuccessAirtimeTopUpCommissionCount": 0,
"totalSuccessDataTopUpCommissionCount": 0,
"totalSuccessCableCommissionCount": 0,
"totalSuccessUtilityCommissionCount": 0,
"totalSuccessBettingCommissionCount": 0,
"totalSuccessBillsPaymentAggregatorCommissionCount": 0,
"totalSuccessWithdrawalAggregatorCommissionCount": 0,
"totalFailedSignUpCommissionCount": 0,
"totalFailedWithdrawalCommissionCount": 0,
"totalFailedTransferCommissionCount": 0,
"totalFailedAirtimeTopUpCommissionCount": 0,
"totalFailedDataTopUpCommissionCount": 0,
"totalFailedCableCommissionCount": 0,
"totalFailedUtilityCommissionCount": 0,
"totalFailedBettingCommissionCount": 0,
"totalFailedBillsPaymentAggregatorCommissionCount": 0,
"totalFailedWithdrawalAggregatorCommissionCount": 0,
"totalSignUpCommission": 0,
"totalWithdrawalCommission": 0,
"totalTransferCommission": 0,
"totalAirtimeTopUpCommission": 0,
"totalDataTopUpCommission": 0,
"totalCableCommission": 0,
"totalUtilityCommission": 0,
"totalBettingCommission": 0,
"totalBillsPaymentAggregatorCommission": 0,
"totalWithdrawalAggregatorCommission": 0,
"totalWayaRevenue": 0,
"totalSuccessDisbursed": 0,
"totalFailedDisbursed": 0,
"totalReferralDisbursed": 0
}
const CommissionPayment = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [intervals, setInterval] = useState('overall');
  const [overallAnalysis, setOverall] = useState<Overall>(initial);
  
  const fetchOverview2 = async () => {
    setIsLoading(true);
    const res = await httpGet(`${customBaseUrl.baseUrl}/reports/referral/admin/analysis`,'',false);
    if (res?.status) {
      //setFiltered(response.data.content)
      setIsLoading(false)
      setOverall(res.data);
    } else {
      setOverall(initial);
    }
  };
  useEffect(() => {
    // fetchOverview();
    fetchOverview2();
  }, []);
  return (
    <div className='h-full bg-gray-accent'>
      <div className='flex items-center justify-between py-5'>
      <Typography variant='h5' color="black" className='text-base font-bold'>Hey {localStorage.name} -</Typography>
        <div className='flex gap-5'>
          <Link to="/user/generate_report" className='text-center bg-primary text-white py-2.5 px-4 font-semibold rounded-lg flex items-center'><AddCircleIcon className='mr-2 text-xl' />Generate report</Link>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <DashboardCards title='total value of commission payment' value={overallAnalysis?.totalTransactionCommission.toString()} percent="+36%" />
        <DashboardCards title='total count of commission payment' value={overallAnalysis.totalTransferCommissionCount.toString()} percent="-36%" />
        <DashboardCards title='total value of utility commission payment' value={overallAnalysis.totalUtilityCommission.toString()} percent="+36%" />
        <DashboardCards title='total count of utility commission payment' value={overallAnalysis.totalUtilityCommissionCount.toString()} percent="+36%" />
        <DashboardCards title='total value of data commission payment' value={overallAnalysis.totalDataTopUpCommission.toString()} percent="+36%" />
        <DashboardCards title='total count of data commission' value={overallAnalysis.totalDataTopUpCommissionCount.toString()} percent="+36%" />
        <DashboardCards title='total value of cable commission payment' value={overallAnalysis.totalCableCommission.toString()} percent="+36%" />
        <DashboardCards title='total count of cable commission count' value={overallAnalysis.totalCableCommissionCount.toString()} percent="-36%" />
        <DashboardCards title='total value of signup commission payment' value={overallAnalysis.totalSignUpCommission.toString()} percent="+36%" />
        <DashboardCards title='total count of signup commission count' value={overallAnalysis.totalSignUpCommissionCount.toString()} percent="-36%" />
        <DashboardCards title='total value of airtime commission payment' value={overallAnalysis.totalAirtimeTopUpCommission.toString()} percent="+36%" />
        <DashboardCards title='total count of airtime commission count' value={overallAnalysis.totalAirtimeTopUpCommissionCount.toString()} percent="-36%" />
      
        {/* <DashboardCards title='total value of general commission payment' value="350" percent="+36%" />
        <DashboardCards title='total count of general commission payment' value="33493" percent="+36%" />
        <DashboardCards title='total value of specific commission payment' value="350" percent="+36%" />
        <DashboardCards title='total count of specific commission payment' value="33493" percent="+36%" />
        <DashboardCards title='total value of billpayment commission payment' value="2500" percent="+36%" />
        <DashboardCards title='total count of billpayment commission payment' value="1800" percent="-36%" />
        <DashboardCards title='total value of pos commission payment' value="2500" percent="+36%" />
        <DashboardCards title='total count of pos commission payment' value="1800" percent="-36%" />
        <DashboardCards title='total value of pos commission payment to up' value="2500" percent="+36%" />
        <DashboardCards title='total count of pos commission payment to up' value="1800" percent="-36%" />
        <DashboardCards title='total value of pos commission payment to isw' value="2500" percent="+36%" />
        <DashboardCards title='total count of pos commission payment to isw' value="1800" percent="-36%" /> */}
      </div>

      {/* <Chart
        chartType="LineChart"
        className='mt-10'
        data={data}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      /> */}
    </div>
  )
}

export default CommissionPayment
