import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ShareIcon from "@mui/icons-material/Share";
import CommentIcon from "@mui/icons-material/Comment";
import TagIcon from "@mui/icons-material/Tag";
import SyncIcon from "@mui/icons-material/Sync";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MovieIcon from "@mui/icons-material/Movie";
import ImageIcon from "@mui/icons-material/Image";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";

type Props = {};

const routes: {
  url: string;
  name: string;
  icon: ReactElement<any>;
}[] = [
  {
    url: "/wayagram/user",
    name: "User",
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    url: "/wayagram/commission_payment",
    name: "Commision Payment",
    icon: <CardMembershipOutlinedIcon />,
  },
  {
    url: "/wayagram/revenue",
    name: "Revenue",
    icon: <EqualizerOutlinedIcon />,
  },
  {
    url: "/wayagram/product",
    name: "Product",
    icon: <Inventory2Icon />,
  },
  {
    url: "/wayagram/share",
    name: "Share",
    icon: <ShareIcon />,
  },
  {
    url: "/wayagram/comment",
    name: "Comment",
    icon: <CommentIcon />,
  },
  {
    url: "/wayagram/hashtag",
    name: "Hashtag",
    icon: <TagIcon />,
  },
  {
    url: "/wayagram/synchronization",
    name: "Synchronization",
    icon: <SyncIcon />,
  },
  {
    url: "/wayagram/follow",
    name: "Follow",
    icon: <PersonAddAlt1Icon />,
  },
  {
    url: "/wayagram/post",
    name: "Post",
    icon: <PostAddIcon />,
  },
  {
    url: "/wayagram/media",
    name: "Media",
    icon: <MovieIcon />,
  },
  {
    url: "/wayagram/image",
    name: "Image",
    icon: <ImageIcon />,
  },
  {
    url: "/wayagram/chat",
    name: "Chat",
    icon: <MarkUnreadChatAltIcon />,
  },
  {
    url: "/wayagram/kyc_verification",
    name: "Kyc verification",
    icon: <PhoneIphoneOutlinedIcon />,
  },
  {
    url: "/wayagram/sms",
    name: "Sms",
    icon: <CreditScoreOutlinedIcon />,
  },
  {
    url: "/wayagram/account_creation_and_login",
    name: "Account creation & login",
    icon: <MarkunreadMailboxOutlinedIcon />,
  },
];

const GramSidebar = (props: Props) => {
  return (
    <>
      <ul className="flex flex-col h-full justify-evenly mt-14 float-left gap-0.5 clear-right w-[21vw]">
        {routes.map((route, index) => (
          <NavLink
            to={route.url}
            key={index}
            className={({ isActive, isPending }) =>
              isActive
                ? "bg-[#FFEFEA] text-[#FF5D2E] py-2 h-full hover:bg-gray-100 px-8 flex items-center rounded-lg"
                : "py-2 h-full text-[#8C8C8C] flex items-center hover:bg-gray-100 px-8 rounded-lg"
            }
          >
            <span className="mr-3 text-sm">{route.icon}</span> {route.name}
          </NavLink>
        ))}
      </ul>
    </>
  );
};

export default GramSidebar;
